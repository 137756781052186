import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import "../styles/Navbar2.css";
import logo from "../assets/logo.png"; // Import your logo image
import { Link } from "react-router-dom";

const Navbar2 = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLogoClick = () => {
    navigate('/home'); // Navigate to /home
    setMenuOpen(false); // Close the menu if it's open
  };

  const handleContactClick = () => {
    navigate('/contact'); // Navigate to /contact
    setMenuOpen(false); // Close the menu if it's open
  };

  return (
    <nav className="navbarrr">
      <div className="navbarr-logo" onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
        <h1>New Age Acoustics</h1>
        <img src={logo} alt="New Age Acoustics Logo" className="navbar-logo-image" />
      </div>
      <button className="navbar-hamburger" onClick={toggleMenu}>
        <span className="hamburger-icon"></span>
      </button>
      <ul className={`navbar-links ${menuOpen ? "open" : ""}`}>
        <li><Link to="/home">Home</Link></li>
        <li><Link to="/about">About Us</Link></li>
        <li><Link to="/services">Services</Link></li>
        <li><Link to="/projects">Projects</Link></li>
        <li><Link to="/careers">Careers</Link></li>
        <li><Link to="/contact">Contact Us</Link></li>
      </ul>
      <div className="navbar-contact">
        <button className="contact-button" onClick={handleContactClick}>Contact Us</button>
      </div>
    </nav>
  );
};

export default Navbar2;
