import React, { useState } from "react";
import "../styles/Contact.css";
import Navbar2 from "./Navbar2";
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from "react-icons/fa";

const Contact = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const scriptURL = "https://script.google.com/macros/s/AKfycbwBa7WZRMMt1AEcZQOOlLIOaONf4odlJUUBvhrJ-lTFz0_VjY66JBDYEdfXkvkEUsjJ/exec"; // Replace with your Web App URL
    const formData = new FormData(event.target);

    try {
      const response = await fetch(scriptURL, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        alert("Thanks for reaching out. Your message has been sent. We will contact you soon!");
        event.target.reset(); // Clear the form
      } else {
        alert("Oops! Something went wrong. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("There was an error sending your message. Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <Navbar2 />
      <div className="contact">
        <div className="contact-container">
          {/* Left Section */}
          <div className="contact-info">
            <h4 className="contact-heading">Contact Us</h4>
            <h2 className="contact-title">GET IN TOUCH WITH US</h2>
            <p className="contact-description">
              Need to get in touch with us? Either fill out the form with your
              inquiry or find the email below to message us!
            </p>

            <div className="contact-details">
              <div className="detail-item">
                <FaMapMarkerAlt className="detail-icon" />
                <div>
                  <h5>Our Location</h5>
                  <p>
                    1218, Pearls Omaxe Tower 2,
                    <br />
                    Netaji Subhash Place,
                    <br />
                    Pitampura,
                    <br />
                    Delhi - 110088
                  </p>
                </div>
              </div>

              <div className="detail-item">
                <FaPhoneAlt className="detail-icon" />
                <div>
                  <h5>Phone Number</h5>
                  <p>(+91) 98-1118-3646</p>
                  <p>(+91) 98-1116-5222</p>
                </div>
              </div>

              <div className="detail-item">
                <FaEnvelope className="detail-icon" />
                <div>
                  <h5>Email Address</h5>
                  <p>ajayvats@newageacoustics.com</p>
                  <p>newageacoustics@gmail.com</p>
                </div>
              </div>
            </div>
          </div>

          {/* Right Section */}
          <div className="contact-form">
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="name"
                placeholder="Your Name"
                className="form-input"
                required
              />
              <input
                type="email"
                name="email"
                placeholder="Your Email"
                className="form-input"
                required
              />
              <input
                type="text"
                name="phone"
                placeholder="Your Phone"
                className="form-input"
                required
                pattern="[\+]?[0-9]*" // Allows numbers and the "+" sign at the beginning
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9+]/g, ''); // Remove non-numeric and non "+" characters
                }}
              />
              <textarea
                name="message"
                placeholder="Your Message"
                className="form-textarea"
                rows="4"
                required
              ></textarea>
              <button type="submit" className="form-button" disabled={isSubmitting}>
                {isSubmitting ? "Sending..." : "Send Message"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
