import React from "react";
import Navbar2 from "./Navbar2";
import "../styles/AboutUs.css";
import aboutImage from "../assets/logo.png"; // Replace with the actual image path

const AboutUs = () => {
  return (
    <div className="about-us">
      <Navbar2 />
      {/* Top Section */}
      <div className="about-us-top">
        <div className="about-us-heading-container">
          <h1 class="gallery-headings">who are we?</h1>
        </div>
        <div className="about-us-image-container">
          <img src={aboutImage} alt="About New Age Acoustics" />
        </div>
      </div>

      {/* Bottom Section */}
      <div className="about-us-bottom">
        <p className="about-us-description">
          New Age Acoustics Pvt. Ltd. specializes in the construction and design
          of state-of-the-art auditoriums and halls. With over 25 years of experience,
          we provide a one-stop solution for acoustic challenges, delivering
          exceptional results for auditoriums, multipurpose halls, and more.  
          Our clients include government departments, recognized for our quality and punctuality.
        </p>
        <br />
        <h3 className="about-us-description">Our Strategy</h3>
        <p className="about-us-description">
          We believe in building and sustaining relationships through an open communication bridge. 
          Our prime aim is to provide quality service to clients and help in this technically challenged subject of hall designing.
        </p>
        <br/>
        <h3 className="about-us-description">Our Strengths</h3>
        <ul className="about-us-strengths about-us-description">
          <li><b>Transparent Communication:</b> We prioritize open and honest communication with our clients to ensure alignment and trust throughout the project.</li>
          <li><b>Commitment to Quality:</b> Our primary focus is on using premium-quality materials to deliver durable and superior results.</li>
          <li><b>Proactive Recommendations:</b> We go the extra mile by suggesting amendments and modifications that enhance the project’s value and functionality.</li>
          <li><b>Cost-Effective Solutions:</b> We carefully select materials that balance quality and affordability, supported by thorough market research.</li>
          <li><b>Client-Centric Approach:</b> We maintain regular follow-ups with clients to keep them updated and address any concerns promptly.</li>
          <li><b>Seamless On-Site Coordination:</b> Our team ensures efficient collaboration on-site, reducing delays and enhancing execution.</li>
          <li><b>Post-Completion Support:</b> We offer comprehensive after-execution follow-ups to guarantee client satisfaction and address any post-project needs.</li>
          <li><b>Technical Expertise:</b> Backed by a skilled and experienced technical team, we ensure the highest standards of execution and innovation.</li>
        </ul>
      </div>
    </div>
  );
};

export default AboutUs;
