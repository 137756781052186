import React, { useState } from "react";
import Navbar2 from "./Navbar2";
import "../styles/Careers.css";

const Careers = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    resume: null,
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: files ? files[0] : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert(
      "Thank you for your interest! We will review your information and reach out to you if there are any openings."
    );
    setFormData({ name: "", email: "", resume: null });
  };

  return (
    <div>
      <Navbar2 />
    <div className="careers-page">
      <h1 class="gallery-heading">careers.</h1>
      <div className="careers-header">
        <p>
          At New Age Acoustics, we value passion, creativity, and excellence. While we are not hiring at the moment, 
          we’re always looking for talented individuals to join our network. Leave your details, and we’ll get back to you when opportunities arise.
        </p>
      </div>
      <div className="careers-form-container">
        <form onSubmit={handleSubmit} className="careers-form">
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              placeholder="Enter your full name"
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              placeholder="Enter your email address"
            />
          </div>
          <div className="form-group">
            <label htmlFor="resume">Resume</label>
            <input
              type="file"
              id="resume"
              name="resume"
              onChange={handleChange}
              accept=".pdf,.doc,.docx"
              required
            />
          </div>
          <button type="submit" className="submit-btn">
            Submit
          </button>
        </form>
      </div>
    </div>
    </div>

  );
};

export default Careers;
