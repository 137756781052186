import React from 'react';
import '../styles/Statistics.css';

const Statistics = () => {
  const stats = [
    { value: '25+', label: 'Years of Experience' },
    { value: '250+', label: 'Auditoriums' },
    { value: '275+', label: 'Projects' },
  ];

  return (
    <div className="statistics">
      {stats.map((stat, index) => (
        <div key={index} className="stat-item">
          <h1 className="stat-value">{stat.value}</h1>
          <p className="stat-label">{stat.label}</p>
        </div>
      ))}
    </div>
  );
};

export default Statistics;
