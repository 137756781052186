import React from "react";
import "../styles/Services.css";
import Navbar2 from "./Navbar2";
import service1 from "../assets/audi2.jpeg"; // Example images
import service2 from "../assets/sideWings2.jpeg";
import service3 from "../assets/catwalk.jpg";
import service4 from "../assets/audi16.jpg";
import service5 from "../assets/fire.jpg";
import service6 from "../assets/conference room.jpg";
import service7 from "../assets/audi3.jpg";
import service8 from "../assets/insulation.jpg";
import service9 from "../assets/audi18.jpg";
import service10 from "../assets/av.jpg";
import service11 from "../assets/hvac.png";
import service12 from "../assets/stage light.jpg";

const Services = () => {
  const services = [
    { img: service7, title: "Acoustical Treatment", text: "on Ceiling, Walls, Soffit, Balcony Fascia, Stage Fascia etc." },
    { img: service2, title: "Stage Furnishings viz.", text: "Wings, Borders, Curtains, Cyclorama, Stage Flooring" },
    { img: service3, title: "M.S. Work's viz.", text: "Catwalks, Cyclorama Frame, Set Bars, Trusses etc." },
    { img: service4, title: "Electrical Works", text: "Illumination System and Related Electrical Works for Halls" },
    { img: service5, title: "Fire Fighting", text: "Including Fire Doors" },
    { img: service6, title: "Furnishing", text: "Green rooms, Control Room, Foyers, Conference Desks, Podium, etc." },
    { img: service1, title: "Seating", text: "Auditorium Chairs" },
    { img: service8, title: "Sound / Noise Isolation" },
    { img: service9, title: "Floor contours", text: "Floor Contours for Optimum Viewing" },
    { img: service10, title: "Audi / Video", text: "" },
    { img: service11, title: "HVAC", text: "" },
    { img: service12, title: "Lighting System", text: "Stage / Audi Lighting" },
  ];

  return (
    <div>
    <Navbar2 />
    <div className="services">
        <h1 class="gallery-heading">what we do...</h1>
        <div className="services-grid">
        {services.map((service, index) => (
          <div className="service-card" key={index}>
            <img src={service.img} alt={service.title} className="service-image" />
            <h3 className="service-title">{service.title}</h3>
            <p className="service-text">{service.text}</p>
          </div>
        ))}
      </div>
    </div>
    </div>
  );
};

export default Services;
