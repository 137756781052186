import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from './components/ScrollToTop';
import Home from "./components/Home";
import AboutUs from "./components/AboutUs";
import Services from "./components/Services";
import Projects from "./components/Projects";
import Careers from "./components/Careers";
import Contact from "./components/Contact";
import Footer1 from "./components/Footer1";
import Footer2 from "./components/Footer2";
import "./App.css"; // Main app styling

const App = () => {
  return (
    <Router>
    <ScrollToTop />
    <div className="App" style={{backgroundColor: 'white'}}>
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </main>
      <footer>
        <Footer1/>
        <Footer2/>
      </footer>
    </div>
    </Router>
  );
};

export default App;
