import React from "react";
import "../styles/InfoSection.css"; // Add styles here
import Navbar from "./Navbar";
import audi1 from "../assets/audi1.jpeg"
import audi2 from "../assets/audi2.jpeg"
import audi3 from "../assets/audi3.jpg"

const InfoSection = () => {
  return (
    <div className="info-section">
      <div className="text-content">
        <h1 style={{fontSize:'3em'}}>
          We Help People Shape Spaces With Cutting-Edge Acoustics And Unmatched Expertise.
        </h1>
        <p>
          Supported by a fully trained and dedicated team of experts, we work to exceed
          our clients' expectations. Equipped with the latest tools and machinery, we
          ensure seamless execution of every project.
        </p>
        </div>
      <div className="image-gallery">
        <img src={audi1} alt="Architecture 1" className="large-image" />
        <div className="small-images">
          <img src={audi2} alt="Architecture 2" />
          <img src={audi3} alt="Architecture 3" />
        </div>
      </div>
    </div>
  );
};

export default InfoSection;
