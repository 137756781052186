import React from "react";
import "../styles/Process.css";
import processImage from "../assets/audi17.jpg"; // Replace with your image path

const Process = () => {
  const steps = [
    "Site Evaluation",
    "Design Concept and Drawings",
    "Budgetary Estimates",
    "Supply of Material",
    "Installation Testing and Commissioning",
    "Site Validation",
    "Training Client's Staff",
    "After Sales Service / Maintenance",
  ];

  return (
    <div className="process-container">
      <div className="process-content">
      <h1 class="gallery-heading">our process.</h1>
        <ul className="process-steps">
          {steps.map((step, index) => (
            <li key={index} className="process-step">
              <div className="step-number">{`0${index + 1}`}</div>
              <span>{step}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className="process-image">
        <img src={processImage} alt="Process" />
      </div>
    </div>
  );
};

export default Process;
