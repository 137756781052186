import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import logo from '../assets/logo.png';
import '../styles/Footer1.css';

function Footer1(props) {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleNavigation = (path) => {
    navigate(path); // Navigate to the specified path
  };

  return (
    <div style={{ borderTop: '1px' }}>
      <footer className="footer">
        <div className="footer-content">
          <div className="footer-column">
            <h3>Our Organisation</h3>
            <ul className='footer-font'>
              <li onClick={() => handleNavigation('/home')}>Home</li>
              <li onClick={() => handleNavigation('/about')}>About Us</li>
              <li onClick={() => handleNavigation('/projects')}>Our Work</li>
              <li onClick={() => handleNavigation('/services')}>Services</li>
            </ul>
          </div>
          <div className="footer-column">
            <h3>Join Us</h3>
            <ul className='footer-font'>
              <li onClick={() => handleNavigation('/careers')}>Careers</li>
            </ul>
          </div>
          <div className="footer-column">
            <h3>Contact</h3>
            <ul className='footer-font'>
              <li onClick={() => handleNavigation('/contact')}>Contact Us</li>
            </ul>
          </div>
          <div className="footer-column">
            <img src={logo} alt="Logo" />
            <p className='footer-font address'>
              1218, Pearls Omaxe Tower 2,<br />
              Netaji Subhash Place,
              Pitampura,<br />
              Delhi - 110088
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer1;
