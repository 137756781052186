import React from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import InfoSection from "./InfoSection";
import Process from "./Process";
import Statistics from "./Statistics";
import Experience from "./Experience";
import Types from "./Types";
import "../styles/Home.css";

const Home = () => {
  const navigate = useNavigate();

  const handleQouteClick = () => {
    navigate('/contact');
  }

  return (
    <div>
    <div className="home">
      <Navbar />
      <header className="home-section">
        <div className="home-content">
          <h1>Crafting Perfect Acoustics For Every Space</h1>
          <p>
            Over 25 years of expertise in crafting auditoriums, halls, and multiplexes
            with unmatched quality and precision. Trusted by clients nationwide.
          </p>
          <button className="quote-button" onClick={handleQouteClick}>Get Personal Quote</button>
        </div>
        <div className="home-footer">
          <div className="footer-item" style={{}}>
            Designing | Consultancy | Execution
          </div>
        </div>
      </header>
      </div>
      <InfoSection />
        <Statistics/>
        <Experience />
        <Types />
        <Process />
    </div>
  );
};

export default Home;
