import React, { useState } from 'react';
import '../styles/Projects.css';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';
import Navbar2 from "./Navbar2";

function Projects(props) {
  const north = [
    { project: 'Auditorium, MP Hall and Conference Hall at Supreme Court New Campus', location: 'New Delhi, Delhi' },
    { project: 'Tamil Nadu Guest House', location: 'Chanakyapuri, New Delhi'},
    { project: 'FICCI Auditorium', location: 'New Delhi, Delhi' },
    { project: 'Auditorium in J.N.U. Campus', location: 'New Delhi, Delhi'},
    { project: 'Delhi Karnataka Sangha', location: 'New Delhi , Delhi' },
    { project: 'Guru Gobind Singh College of Commerce - DU', location: 'Delhi, Delhi' },
    { project: 'Jawahar Bhawan(Congress House)', location: 'New Delhi, Delhi' },
    { project: 'Development of Software for Project Information Centres DRDO', location: 'New Delhi, Delhi' },
    { project: 'Indian Council for Cultural Relations Azad Bhawan', location: 'New Delhi, Delhi' },
    { project: 'Auditorium at CAPFIM', location: 'New Delhi, Delhi' },
    { project: 'NSIT', location: 'Dwarka, Delhi' },
    { project: 'Auditorium at NTPC, Netra', location: 'Greater Noida, Uttar Pradesh' },
    { project: 'Auditorium at Indira Gandhi Bhawan', location: 'New Delhi, Delhi' },
    { project: 'Auditorium at Graphic Era University', location: 'Dehradun, Uttarakhand' },
    { project: 'Auditorium at NTPC Jharli', location: 'Jhajjar, Haryana' },
    { project: 'Gautam Buddha University', location: 'Noida, U.P' },
    { project: 'Indira Gandhi Pratishthan', location: 'Lucknow, Uttar Pradesh' },
    { project: 'Lal Bahadur Shastri Institute of Management', location: 'Dwarka, Delhi' },
    { project: '26 Battalion', location: 'Ayanagar, Ghitorni, Delhi' },
    { project: 'Narora Atomic Power Station', location: 'Narora, U.P' },
    { project: 'Tulas Institute of Technology', location: 'Dehradun , Uttarakhand' },
    { project: 'Jindal Industries', location: 'Hissar, Haryana' },
    { project: 'SARDA Mines', location: 'Barbil, Orissa' },
    { project: 'Dr. Ram Manohar Lohiya National Law University', location: 'Lucknow, U.P' },
    { project: 'Maharashtra Rangayan', location: 'New Delhi, Delhi' },
    { project: 'Logix Cyber Park', location: 'Noida, U.P.' },
    { project: 'Manav Sthali School', location: 'New Delhi, Delhi' },
    { project: 'National Dairy Development Board', location: 'Karnal, Haryana' },
    { project: 'Modern School', location: 'Faridabad, Haryana'},
    { project: 'Open Theatre in National Bal Bhavan', location: 'New Delhi , Delhi'},
    { project: 'Rishikul Vidyapeeth', location: 'Sonepat, Haryana'},
    { project: 'Rukmini Devi Public School', location: 'Delhi'},
    { project: 'Bipin Tripathi Kumaon Institute of Technology', location: 'Almora, Uttarakhand' },
    { project: 'DAV School', location: 'Faridabad Sec-14, Haryana'},
    { project: 'YMCA Recreation Centre', location: 'Greater Noida, UP ' },
    { project: 'Maharaja Agrasen School', location: 'Delhi, Delhi' },
    { project: 'ASIA PACIFIC Institute of Management', location: 'Sarita Vihar, Delhi' },
    { project: 'Sacred Heart School', location: 'Ludhiana, Punjab' },
    { project: 'Hemvati Nandan Bahuguna Garhwal University', location: 'Srinagar, Uttarakhand' },
    { project: 'DAV School', location: 'Faridabad Sec-49, Haryana' },
    { project: 'Rishikul Vidyapeeth - 2', location: 'Sonepat, Haryana' },
    { project: 'Indraprastha School', location: 'New Delhi, Delhi' },
    { project: 'Cambridge School', location: 'New Delhi, Delhi ' },
    { project: 'Defence Research & Development Organization', location: 'New Delhi, Delhi' },
    { project: 'Khalsa College - DU', location: 'New Delhi, Delhi' },
    { project: 'Army Base', location: 'Ghaziabad, U.P.' },
    { project: 'DAV School Sec-14 ', location: 'Gurugram, Haryana' },
    { project: 'St Theresa\'s Convent School', location: 'Karnal, Haryana' },
    { project: 'Dynasty International School', location: 'Faridabad, Haryana' },
    { project: 'Rukmini Devi Institute of Advanced Studies', location: 'Rohini, Delhi' },
    { project: 'Agricultural Produce Market Committees', location: 'Azadpur, Delhi' },
    { project: 'GSVM Medical College', location: 'Kanpur, U.P' },
    { project: 'Venkateshwar International School', location: 'Dwarka, Delhi' },
    { project: 'I.D.S.A. Building', location: 'New Delhi, Delhi' },
    { project: 'S.P.M. College', location: 'Punjabi Bagh, Delhi' },
    { project: 'Maharaja Surajmal Institute', location: 'New Delhi, Delhi' },
    { project: 'R.M.L. Hospital New Block', location: 'New Delhi, Delhi' },
    { project: 'BBD, National Institute of Technology and Management', location: 'Lucknow, U.P' },
    { project: 'Modern School', location: 'Noida, U.P' },
    { project: 'GD Goenka Public School', location: 'Faridabad, Haryana' },
    { project: 'Lancers International School', location: 'Gurugram, Haryana' },
    { project: 'Ahlcon International School', location: 'New Delhi, Delhi' },
    { project: 'Rishikul Vidyapeeth', location: 'Sonipat, Haryana' },
    { project: 'Dyal Singh College - DU', location: 'New Delhi, Delhi' },
    { project: 'Bharatendu Natya Academy', location: 'Lucknow, Uttar Pradesh' },
    { project: 'Maharaja Agrasen Institute of Technology', location: 'Delhi, Delhi' },
    { project: 'Ahlcon Public School', location: 'New Delhi, Delhi' },
    { project: 'Songtsen Library', location: 'Kirsali, Uttarakhand' },
    { project: 'ILLL, ARC Building - DU', location: 'Delhi, Delhi' },
    { project: 'Delhi Public School', location: 'Chandigarh' },
    { project: 'Wynberg-Allen School', location: 'Mussoorie, Uttarakhand' },
    { project: 'IIMT', location: 'New Delhi, Delhi' },
    { project: 'Mata Sundri College - DU', location: 'New Delhi, Delhi' },
    { project: 'Council Hall & Meeting Halls, MCD Civic Centre', location: 'New Delhi, Delhi' },
    { project: 'Institute of Management Sciences', location: 'Ghaziabad, Uttar Pradesh' },
    { project: 'Nagar Nigam Dehradun', location: 'Dehradun, Uttarakhand' },
    { project: 'Maharaja Agrasen Medical College', location: 'Agroha, Haryana' },
    { project: 'Rama Krishna Mission', location: 'New Delhi, Delhi' },
    { project: 'Auditorium at Sri Ram School', location: 'Gurgaon, Haryana' },
    { project: 'Auditorium at KIET', location: 'Ghaziabad, Uttar Pradesh' },
    { project: 'Exhibition Halls, I.E.M.L.', location: 'Greater Noida, Uttar Pradesh' },
    { project: 'Auditorium at Lala Lajpat Rai Medical College', location: 'Meerut, Uttar Pradesh' },
    { project: 'Auditorium at Kamaon University', location: 'Almora, Uttarakhand' },
    { project: 'Auditorium at Polytechnic', location: 'Dwarahat, Uttarakhand' },
    { project: 'Auditorium at Maulana Mohammad Ali Johar Research Centre', location: 'Rampur, Uttar Pradesh' },
    { project: 'Kalidasa Auditorium at Virsa Vihar Kendra', location: 'Patiala, Punjab' },
    { project: 'Auditorium at Paryavaran Bhawan', location: 'New Delhi, Delhi' },
    { project: 'Seminar Halls at The Sri Ram School', location: 'Gurgaon, Haryana' },
    { project: 'Auditorium at Adhyatmik Sadhna Kendra', location: 'New Delhi, Delhi' },
    { project: 'Auditorium at IMT', location: 'Ghaziabad, Uttar Pradesh' },
    { project: 'Auditorium at NCOF', location: 'Ghaziabad, Uttar Pradesh' },
    { project: 'Auditorium at BMIET', location: 'Sonepat, Haryana' },
    { project: 'IMT, CDL centres & Studios', location: 'Delhi & NCR' },
    { project: 'Auditorium at Paul George International School', location: 'New Delhi, Delhi' },
    { project: 'Auditorium at Panchayat Bhawan', location: 'Moradabad, Uttar Pradesh' },
    { project: 'Amphitheatre and Interior works at Signature School', location: 'Gurgaon, Haryana' },
    { project: 'Auditorium at Convention Centre', location: 'Jammu, Jammu & Kashmir' },
    { project: 'Auditorium at Uttarakhand Technical University', location: 'Dehradun, Uttarakhand' },
    { project: 'Auditorium at GEMS School', location: 'Gurgaon, Haryana' },
    { project: 'Auditorium at IRDT', location: 'Dehradun, Uttarakhand' },
    { project: 'Auditorium at Sushila Tiwari Medical College', location: 'Haldwani, Uttarakhand' },
    { project: 'Auditorium at ICAT', location: 'Gurgaon, Haryana' },
    { project: 'Stadium at Shiv Nadar University', location: 'Dadri, Uttar Pradesh' },
    { project: 'Auditorium at NHPC', location: 'Faridabad, Haryana' },
    { project: 'MP Hall at Amity School', location: 'Gurgaon, Haryana' },
    { project: 'Auditorium at DSB College', location: 'Nainital, Uttarakhand' },
    { project: 'Auditorium at Aravali International School', location: 'Faridabad, Haryana' },
    { project: 'Auditorium at Lotus Valley School', location: 'Gurgaon, Haryana' },
    { project: 'MP Hall at B.M. Munjal University', location: 'Manesar, Haryana' },
    { project: 'Auditorium at Lotus Valley School', location: 'Noida, Uttar Pradesh' },
    { project: 'Auditorium at YMCA University', location: 'Faridabad, Haryana' },
    { project: 'Auditorium for DCRUST', location: 'Sonepat, Haryana' },
    { project: 'Auditorium at Step by Step School', location: 'Noida, Uttar Pradesh' },
    { project: 'Auditorium cum Seminar Hall at Arya PG College', location: 'Panipat, Haryana' },
    { project: 'Auditorium at Aravali International School, Sector-85', location: 'Faridabad, Haryana' },
    { project: 'Auditorium at Gurunanak University', location: 'Amritsar, Punjab' },
    { project: 'Auditorium at The Sriram School, Phase-3', location: 'Gurgaon, Haryana' },
    { project: 'Auditorium at ICAT', location: 'Manesar, Haryana' },
    { project: 'Auditorium at GVM College', location: 'Sonepat, Haryana' },
    { project: 'Auditorium at Swaranprastha School', location: 'Sonepat, Haryana' },
    { project: 'Auditorium at Sarvodaya Hospital', location: 'Faridabad, Haryana' },
    { project: 'Auditorium at MRV School', location: 'Dwarka, Delhi' },
    { project: 'Auditorium at Sparsh School', location: 'Noida Extension, Uttar Pradesh' },
    { project: 'Auditorium at SGCL', location: 'Aligarh, Uttar Pradesh' },
    { project: 'G E International Operations Co. Inc.', location: 'New Delhi, Delhi' },
    { project: 'Rukmani Devi Public School', location: 'Pitampura, Delhi' },
    { project: 'Peregrine Securities', location: 'Gurgaon, Haryana' },
    { project: 'Delhi Happy Model School', location: 'Bhogal, New Delhi' },
    { project: 'Agrasain School', location: 'Ashok Vihar, New Delhi' },
    { project: 'St. Lawrence Convent School', location: 'Geeta Colony, New Delhi' },
    { project: 'Hindu College, Delhi University', location: 'New Delhi, Delhi' },
    { project: 'A.E.P.C (5 Nos)', location: 'Sector-44, Gurgaon, Haryana' },
    { project: 'G.D. Goenka School', location: 'Ballabhgarh, Uttar Pradesh' },
    { project: 'Sushila Tiwary Medical College (5 Nos)', location: 'Haldwani, Uttarakhand' },
    { project: 'Everest Cineplex', location: 'Rewari, Haryana' },
    { project: 'New Kavita Palace Cinema', location: 'Noida, Uttar Pradesh' },
    { project: 'New Empire Cinema', location: 'Dehradun, Uttarakhand' },
    { project: 'Multiplex at Collage Mall (Big Cinema), 5 Screens', location: 'Jalandhar, Punjab' },
    { project: 'Palace Theatre', location: 'Allahabad, Uttar Pradesh' },
    { project: 'Multiplex - Parker Mall', location: 'Sonepat, Haryana' },
    { project: 'SARB Multiplex', location: 'Jalandhar, Punjab' },
    { project: 'RIMPA', location: 'Chanakyapuri, New Delhi' },
    { project: 'Kasturi Ram College', location: 'Prashant Vihar, Delhi' },
    { project: 'Lakhani House', location: 'Faridabad, Haryana' },
    { project: 'Mr. J.S. Kohli, Director, Frankfinn Airhostess Training Institute', location: 'Gurgaon, Haryana' },
    { project: 'Home Theater', location: 'Pitampura, Delhi' },
    { project: 'Farmhouse at Bijwasan', location: 'Delhi, Delhi' },
    { project: 'Hotel The Lalit', location: 'Chandigarh, Chandigarh' },
    { project: 'Hotel Shanti Palace', location: 'Mahipalpur, New Delhi' },
    { project: 'Shri Vishnu Eatables Ltd.', location: 'Kaithal, Haryana' },
    { project: 'Rukmani Devi Public School (3 Nos)', location: 'Pitampura, Delhi' },
    { project: 'Apeejay School', location: 'Pitampura, Delhi' },
    { project: 'Sarvada Modern School', location: 'Karawal Nagar, Delhi' },
    { project: 'Rishikul Vidyapeeth', location: 'Alipur, Delhi' },
    { project: 'Multi-Gym for DDA', location: 'Lawrence Road, New Delhi' },
    { project: 'Multi-Gym for DDA', location: 'Ashok Vihar, New Delhi' },
    { project: 'Rishikul Vidyapeeth', location: 'Dewru Road, Sonepat, Haryana' },
    { project: 'Generators & AHU Rooms at Manav Sthali School', location: 'New Rajinder Nagar, New Delhi' },
    { project: 'Generators & AHU Rooms at Modern School', location: 'Faridabad, Haryana' },
    { project: 'Hotel Shanti Palace', location: 'Mahipalpur, New Delhi' },
    { project: 'A.E.P.C', location: 'Sector-44, Gurgaon, Haryana' },
  ];

  const south = [
    { project: 'Auditorium at Nuclear Fuel Complex', location: 'Hyderabad, Telangana' },
    { project: 'Auditorium at Training Centre at BE Estate, Bharat Electronics Limited', location: 'Bangalore, Karnataka' },
    { project: 'Auditorium in PDIC at BEL Estate', location: 'Bangalore, Karnataka' },
    { project: 'Oakridge International School', location: 'Hyderabad, Telangana' },
    { project: 'Auditorium at Oakridge International School', location: 'Bangalore, Karnataka' },
    { project: 'Auditorium at GKVK', location: 'Bangalore, Karnataka' },
    { project: 'Auditorium at Oakridge International School', location: 'Hyderabad, Telangana' },
    { project: 'Auditorium at JNTUA', location: 'Anantapur, Andhra Pradesh' },
    { project: 'Auditorium at EURO School', location: 'Kukatpally, Hyderabad, Telangana' },
  ];

  const east = [
    { project: 'Auditorium at Government Medical College', location: 'Bhawanipatna, Odisha' },
    { project: 'Auditorium at Government Medical College', location: 'Keonjhar, Odisha' },
    { project: 'Auditorium at Xavier University', location: 'Bhubaneswar, Odisha' },
    { project: 'Auditorium for SAIL', location: 'Keonjhar, Odisha' },
    { project: 'Auditorium at IIFT', location: 'Kolkata, West Bengal' },
    { project: 'Auditorium at NPGCL, NTPC', location: 'Nabinagar, Bihar' },
    { project: 'Auditorium at Irrigation Bhawan', location: 'Guwahati, Assam' },
    { project: 'Cultural Society', location: 'Guwahati, Assam' },
    { project: 'Heritage Institute of Technology', location: 'Kolkata, West Bengal' },
    { project: 'Rajiv Gandhi Indoor Stadium', location: 'Guwahati, Assam' },
    { project: 'Auditorium at KAR Bhawan', location: 'Guwahati, Assam' },
    { project: 'Auditorium at Music Academy', location: 'Kohima, Nagaland' },
    { project: 'Auditorium at Heritage Institute of Management', location: 'Kolkata, West Bengal' },
    { project: 'Auditorium at ADAMAS Institute', location: 'Barasat, Kolkata, West Bengal' },
    { project: 'Auditorium for Music Academy', location: 'Serchhip, Mizoram' },
    { project: 'Multipurpose Hall at ADAMAS International School', location: 'Kolkata, West Bengal' },
    { project: 'Auditorium at Vandya International School', location: 'Guwahati, Assam' },
    { project: 'Hub Multiplex', location: 'Guwahati, Assam' },
  ];

  const west = [
    { project: 'FTII', location: 'Pune, Maharashtra' },
    { project: 'Auditorium at Savitri Bai Phule Pune University', location: 'Pune, Maharashtra' },
    { project: 'Auditorium at Pune University', location: 'Pune, Maharashtra' },
    { project: 'Pratap University', location: 'Jaipur, Rajasthan' },
    { project: 'Auditorium at NTPC', location: 'Solapur, Maharashtra' },
    { project: 'Acoustical treatment in Main Convention Halls (3 nos.) at Mahatma Mandir Convention Centre', location: 'Gandhinagar, Gujarat' },
    { project: 'Multipurpose Hall at EURO International School', location: 'Pune, Maharashtra' },
    { project: 'Auditorium at Namdev Sabhagar, Pune University', location: 'Pune, Maharashtra' },
    { project: 'Auditorium at EURO School', location: 'Airoli, Mumbai, Maharashtra' },
    { project: 'Rukmani Birla Modern High School', location: 'Jaipur, Rajasthan' },
    { project: 'Hotel The Lalit', location: 'Mumbai, Maharashtra' },
  ];

  const center = [
    { project: 'Jindal Steel & Power Limited', location: 'Raigarh, Chhattisgarh' },
    { project: 'Interiors, Electrical, HVAC, Fire Suppression Works in the Auditorium at NTPC', location: 'Gadarwara, Madhya Pradesh' },
    { project: 'People\'s College of Dental Sciences & Research Centre', location: ' Bhopal , M.P.' },
    { project: 'Super Thermal Power Station', location: 'Gadarwara, M.P.', client: 'NTPC' },
  ];

  const renderTable = (data, currentPage, itemsPerPage) => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    return (
      <div>
        <Table striped bordered hover size="md">
          <thead>
            <tr>
              <th>Project Name</th>
              <th>Location</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item, index) => (
              <tr key={index}>
                <td>{item.project}</td>
                <td>{item.location}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  };

  const renderPagination = (dataLength, currentPage, setCurrentPage, itemsPerPage) => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(dataLength / itemsPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <Pagination>
        <Pagination.First onClick={() => setCurrentPage(1)} />
        <Pagination.Prev onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} />
        {pageNumbers.map(number => (
          <Pagination.Item
            key={number}
            active={number === currentPage}
            onClick={() => setCurrentPage(number)}
          >
            {number}
          </Pagination.Item>
        ))}
        <Pagination.Next onClick={() => setCurrentPage(prev => Math.min(prev + 1, pageNumbers.length))} />
        <Pagination.Last onClick={() => setCurrentPage(pageNumbers.length)} />
      </Pagination>
    );
  };

  const [currentPageNorth, setCurrentPageNorth] = useState(1);
  const [currentPageSouth, setCurrentPageSouth] = useState(1);
  const [currentPageEast, setCurrentPageEast] = useState(1);
  const [currentPageWest, setCurrentPageWest] = useState(1);
  const [currentPageCenter, setCurrentPageCenter] = useState(1);
  const itemsPerPage = 20;

  return (
    <div>
      <Navbar2 />
    <div className='container' style={{marginTop: '50px', marginBottom: '150px'}}>
      <h1 class="gallery-heading">projects.</h1>
      <Tabs defaultActiveKey="north" id="justify-tab-example" className="mb-3" justify>
        <Tab eventKey="north" title="North India" className='tab' style={{color:'white'}}>
          {renderTable(north, currentPageNorth, itemsPerPage)}
          {renderPagination(north.length, currentPageNorth, setCurrentPageNorth, itemsPerPage)}
        </Tab>
        <Tab eventKey="east" title="East India">
          {renderTable(east, currentPageEast, itemsPerPage)}
          {renderPagination(east.length, currentPageEast, setCurrentPageEast, itemsPerPage)}
        </Tab>
        <Tab eventKey="south" title="South India">
          {renderTable(south, currentPageSouth, itemsPerPage)}
          {renderPagination(south.length, currentPageSouth, setCurrentPageSouth, itemsPerPage)}
        </Tab>
        <Tab eventKey="west" title="West India">
          {renderTable(west, currentPageWest, itemsPerPage)}
          {renderPagination(west.length, currentPageWest, setCurrentPageWest, itemsPerPage)}
        </Tab>
        <Tab eventKey="central" title="Central India">
          {renderTable(center, currentPageCenter, itemsPerPage)}
          {renderPagination(center.length, currentPageCenter, setCurrentPageCenter, itemsPerPage)}
        </Tab>
      </Tabs>
    </div>
    </div>
  );
  
}

export default Projects;
