import React, { useEffect, useState, useRef } from "react";
import "../styles/Experience.css";
import audi1 from "../assets/audi1.jpeg";
import audi2 from "../assets/audi2.jpeg";
import audi3 from "../assets/audi3.jpg";
import audi4 from "../assets/audi4.jpeg";
import audi5 from "../assets/audi5.jpeg";
import audi6 from "../assets/audi6.jpg";
import audi7 from "../assets/audi7.jpg";
import audi8 from "../assets/audi8.jpg";
import audi9 from "../assets/audi9.jpeg";
import audi10 from "../assets/audi10.jpeg";
import audi11 from "../assets/audi11.jpg";
import audi12 from "../assets/audi12.jpeg";
import audi13 from "../assets/audi13.jpg";
import audi14 from "../assets/audi14.jpg";
import audi15 from "../assets/audi15.jpg";
import audi16 from "../assets/audi16.jpg";
import audi17 from "../assets/audi17.jpg";

const Experience = () => {
  const [focusedIndex, setFocusedIndex] = useState(null);
  const containerRef = useRef(null);

  const projectImages = [
    { src: audi5, name: "Savitribai Phule Pune University", category: "Pune, Maharashtra" },
    { src: audi2, name: "Graphic Era University", category: "Dehradun, University" },
    { src: audi1, name: "XIM University", category: "Bhubaneswar, Odisha" },
    { src: audi3, name: "Supreme Court of India", category: "New Delhi, Delhi" },
    { src: audi6, name: "NHPC", category: "Faridabad, Haryana" },
    { src: audi7, name: "NTPC", category: "Solapur, Maharashtra" },
    { src: audi16, name: "XIM University", category: "Bhubaneswar, Odisha" },
    { src: audi8, name: "Supreme Court of India", category: "New Delhi, Delhi" },
    { src: audi9, name: "GVM College", category: "Sonipat, Haryana" },
    { src: audi10, name: "Jawahar Bhawan", category: "New Delhi, Delhi" },
    { src: audi11, name: "Rajiv Gandhi Indoor Stadium", category: "Guwahati, Assam" },
    { src: audi15, name: "Supreme Court of India", category: "New Delhi, Delhi" },
    { src: audi17, name: "XIM University", category: "Bhubaneswar, Odisha" },
  ];

  // Duplicate the array for infinite scrolling
  const infiniteImages = [...projectImages, ...projectImages, ...projectImages];

  useEffect(() => {
    const container = containerRef.current;

    const handleScroll = () => {
      const containerWidth = container.scrollWidth / 3; // Adjust to one-third
      if (container.scrollLeft >= containerWidth * 2) {
        container.scrollLeft -= containerWidth;
      } else if (container.scrollLeft <= containerWidth) {
        container.scrollLeft += containerWidth;
      }

      const containerCenter = container.offsetWidth / 2;

      let closestIndex = null;
      let smallestDistance = Infinity;

      Array.from(container.children).forEach((item, index) => {
        const itemCenter = item.offsetLeft + item.offsetWidth / 2 - container.scrollLeft;
        const distance = Math.abs(containerCenter - itemCenter);

        if (distance < smallestDistance) {
          smallestDistance = distance;
          closestIndex = index % projectImages.length;
        }
      });

      setFocusedIndex(closestIndex);
    };

    container.addEventListener("scroll", handleScroll);

    // Initial scroll to the middle set for seamless effect
    container.scrollLeft = container.scrollWidth / 3;

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [projectImages.length]);

  return (
    <div className="boto-gallery">
      <h1 class="gallery-heading">gallery.</h1>
      <div className="gallery-container" ref={containerRef}>
        {infiniteImages.map((image, index) => (
          <div
            className={`gallery-item ${
              focusedIndex === index % projectImages.length ? "gallery-item-focused" : ""
            }`}
            key={index}
          >
            <img src={image.src} alt={image.name} />
            <div className="gallery-caption">
              <h3>{image.name}</h3>
              <p>{image.category}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Experience;
