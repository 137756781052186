import React from "react";
import "../styles/Types.css"; // Import the styles
import { FaTheaterMasks, FaBuilding, FaChalkboardTeacher, FaUsers, FaFilm } from "react-icons/fa"; // Example icons from react-icons

const services = [
  { icon: <FaTheaterMasks />, title: "Auditoriums" },
  { icon: <FaBuilding />, title: "Multipurpose Halls" },
  { icon: <FaChalkboardTeacher />, title: "Seminar Halls" },
  { icon: <FaUsers />, title: "Conference Halls" },
  { icon: <FaFilm />, title: "Motion Picture Theatres" },
];

const Types = () => {
  return (
    <div className="service-icons-container">
      {services.map((service, index) => (
        <div key={index} className="service-icon-box">
          <div className="icon">{service.icon}</div>
          <div className="title">{service.title}</div>
        </div>
      ))}
    </div>
  );
};

export default Types;
